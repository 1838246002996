<template>

<div class="account-section-main">

	<div v-if="!list_id" class="my-lists">
		<h2>My Lists</h2>
		<div v-if="lists.length == 0" class="no-results">
			<h3>No lists to display yet!</h3>
		</div>
		<div v-for="(description, wishListId) in lists" :key="'list-' + wishListId" class="wishlist">
			<router-link :to="'/myaccount/lists/' + wishListId">{{ description }}</router-link>
			<div class="loading" v-if="loadingList == wishListId"><i class="fas fa-spinner fa-spin"></i></div>
		</div>
		<div class="new-list">
			<button class="new-button action button" @click="addNewList = true"><span class="material-symbols-outlined">&#xe147</span> Add a list</button>
		</div>
	</div>

	<div v-else class="my-list-detail">
		<h2 class="list-title">
			{{ description }}
			<button class="delete-button" type="button" @click="showConfirmDelete = true"><span class="material-symbols-outlined">&#xe872</span> <span style="display:none;">Delete List</span></button>
		</h2>
		<div class="loading" v-if="loading"><i class="fas fa-spinner fa-spin"></i></div>
		<h2 class="empty-list" v-else-if="!loading && list.length == 0">Your list is empty</h2>
		<template v-for="(item, index) in list" v-else>
			<div class="list-item">
				<router-link :to="'/product/' + item.link_name" class="list-item-pic">
					<img :src="item.image_src" :alt="item.title">
				</router-link>
				<div class="description">
					<router-link :to="'/product/' + item.link_name">
						<h2>{{ item.title }}</h2>
					</router-link>
					<div class="columns columns-3 list-meta">
						<div class="form-pair">
							<span class="label">PID:</span>
							<span class="label-detail">{{ item.product_id }}</span>
						</div>
						<div class="form-pair">
							<span class="label">UPC:</span>
							<span class="label-detail">{{ item.upc }}</span>
						</div>
						<div class="form-pair">
							<span class="label">Available:</span>
							<span class="label-detail">
								<template v-if="item.store_available > 0">
									{{ item.store_available }} in Store
								</template>
								<template v-else-if="item.warehouse_available">
									{{ item.warehouse_available }} at Warehouse
								</template>
								<template v-else>
									Currently Unavailable
								</template>
							</span>
						</div>
					</div>
					<div class="columns columns-2 list-item-controls">
						<button class="button primary" @click="addToCart(item)">Move to Cart</button>
						<button class="button primary" @click="removeFromList(item)">Remove</button>
					</div>
				</div>
				<div class="price" v-html="$formatPrice(item.price)"></div>
				<div class="item-divider"></div>
			</div>
		</template>
		<div class="controls">
			<router-link to="/myaccount/lists" tag="button" class="button action">Back to Lists</router-link>
		</div>

	</div>

	<div v-if="addNewList" class="modal">
		<div class="inner">
			<h2>Create a New List</h2>
			<div class="content">
				<p>Create a list to save items for later.</p>
				<label for="add-list-button">List Name</label>
				<input type="text" id="add-list-button" v-model="newWishListDescription" @click="createWishList()">
			</div>
			<div class="controls">
				<div class="button cancel spaced" @click="addNewList = ''">Cancel</div>
				<div :class="['button', saving || !newWishListDescription ? 'disabled' : 'action']" @click="createWishList">Save List</div>
			</div>
		</div>
	</div>
	
	<div v-if="showConfirmDelete" class="modal">
		<div class="inner">
			<div class="content confirm-content">
				<h3>Are you sure you want to delete list<br>{{ description }}?</h3>
				<div class="controls">
					<button class="button cancel spaced" @click="showConfirmDelete = false">Cancel</button>
					<button :class="['button', saving ? 'disabled' : 'delete']" type="button" @click="deleteList(list_id)">Delete List</button>
				</div>
			</div>
		</div>
	</div>

</div>
</template>

<script>
	export default {
		name: 'my-lists',
        props: ['list_id'],
	data() {
		return {
			loading: true,
			saving: false,
			loadingList: null,
			lists: [],
			list: [],
			description: null,
			newWishListDescription: null,
			message: null,
			addNewList: false,
			showConfirmDelete: false
		}
	},
	created() {
		this.fetchData()
	},
	methods: {
		fetchData() {
			if (this.list_id) {
				this.apiCall({ destination: 'get_item_wish_list', data: { wish_list_id: this.list_id} }).then(obj => {
					this.list = obj.wish_list.items
					this.description = obj.wish_list.description
					this.loading = false
				}).catch(err => {
					this.loading = false
					console.log(err)
				})
			} else {
				this.apiCall({ destination: 'get_list_wish_lists' }).then(obj => {
					this.loadingList = null
					this.lists = obj.wish_lists
				}).catch(err => {
					this.loading = false
					this.loadingList = null
					console.log(err)
				})
			}
		},
		createWishList() {
			if (!this.newWishListDescription) return
			if (this.saving) { return }
			this.saving = true
			this.apiCall({ destination: 'save_item_new_wish_list', data: { description: this.newWishListDescription, product_id: this.productId }}).then(obj => {
				this.saving = false
				this.newWishListDescription = null
				//this.lists = obj.wish_lists
				this.addNewList = false
				this.fetchData()
			}).catch(err => {
				console.log(err)
			})
		},
		deleteList(wishListId) {
			if (this.saving) { return }
			this.saving = true
			this.loadingList = wishListId
			this.apiCall({ destination: 'delete_item_wish_list', data: { wish_list_id: wishListId } }).then(obj => {
				this.saving = false
				this.$router.push('/myaccount/lists')
				this.confirmDelete = false
            }).catch(error => {
				this.loadingList = null
                console.log(error)
            })
		},
		addToCart(item) {
			this.loading = true
			this.apiCall({ destination: 'add_to_cart', data: { product_id: item.product_id, quantity: 1 } }).then(obj => {
				this.loading = false
				if (obj.status == 'success') {
					this.removeFromList(item)
				}
            }).catch(error => {
				this.loading = false
                console.log(error)
            })
		},
		removeFromList(item) {
			this.loading = true
			this.apiCall({ destination: 'remove_from_wish_list', data: { product_id: item.product_id, wish_list_id: this.list_id } }).then(obj => {
				this.loading = false
				if (obj.status == 'success') {
					this.list = obj.wish_list['items']
				}
            }).catch(error => {
				this.loading = false
                console.log(error)
            })
		}
	}

}
</script>

<style lang="scss" scoped>
.my-lists {
	.new-button {
		.material-symbols-outlined { vertical-align: middle; }
	}
	.wishlist {
		margin: 1em;
		width: fit-content;
		height: 1.5em;
		font-size: 1.25em;
		.delete-button {
			display: none;
			height: 2em;
			vertical-align: middle;
			padding: 0 1em;
		}
	}
	.wishlist:hover {
		.delete-button {
			display: inline-block;
		}
	}
}

.my-list-detail {
	.back-to-list-button {
		color: #000;
		.material-symbols-outlined { font-size: 1em; }
	}
	.delete-button {
		display: inline-block;
		color: #000;
		background-color: #fff;
		height: 2em;
		vertical-align: middle;
		padding: 0 1em;
	}
	.list-item {
		display: grid;
		grid-template-columns: 0.75fr 1.5fr 0.5fr;
		grid-gap: 1em;
		border-bottom: 1px solid #808080;
		.list-item-pic {
			align-self: center;
			img { max-width: 10em; }
		}
		.description {
			a {
				color: #000;
				h2 { font-size: 1.25em; }
			}
			.list-item-controls {
				grid-gap: 1em;
				display: grid;
			}
		}
		.price {
			font-size: 1.75em;
			align-self: center;
			justify-self: center;
		}
	}
	.controls {
		margin-top: 1em;
	}
}

@media (max-width: 915px) {
.my-list-detail {
	.list-item {
		grid-template-columns: 1fr 1fr;
		margin-top: 1em;
		.description {
			grid-column: 1/3;
			.list-meta {
				column-gap: 1.5em;
				.form-pair {
					grid-template-columns: 1fr 1fr;
				}
			}
			.list-item-controls {
				margin: 0.5em 0;
			}
		}
		.price {
			grid-row: 1/2;
			grid-column: 2/3;
		}
	}
	
}
}
.modal {
	.confirm-content {
		h3 {
			padding: 1.5em;
		}
	}
	.content {
		text-align: center;
		padding-bottom: 1.5em;
		p {
			padding: 0.75em 0;
		}
		label {
			font-size: .9em;
			color: #282c2e;
			margin: 1em;
		}
		input {
			font-size: 1.1em;
			margin: 0 0 0.5em 0;
			padding: 6px 10px;
			background-color: #fff;
			border: 1px solid #d1d1d1;
			border-radius: 3px;
		}
	}
}

@media (max-width: 650px) {
.modal {
	.inner {
		margin: 10px;
		.confirm-content {
			padding: 0.25em;
			h3 { 
				padding: 0.5em;
				font-size: 1em;
			}
		}
		.controls {
			display: grid;
			grid-auto-flow: column;
			button {
				font-size: 15px;
			}
		}
	}
}
}

</style>